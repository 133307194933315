<template>
  <div class="parent-say bg bg13">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut";
export default {
  name: "ParentSay",
  components: {ClassBut},
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
    forward(){
      this.$router.push({
        name: 'PageTenth',
        query: this.$route.query,
      })
    }
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
</style>
